<template>
  <div class="truck-tab flex">
    <div
      class="flex flex-1 h-full items-center justify-center label"
      :class="[index === activeIndex ? 'active' : null]"
      v-for="(tab, index) in tabs"
      :key="index"
      @click="handleChange(tab)"
    >
      <span class="relative text-16">
        {{ tab.label }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  setup(_, { emit }) {
    const handleChange = e => {
      emit('change', e);
    };
    return {
      handleChange
    };
  }
});
</script>

<style lang="less" scoped>
.truck-tab {
  height: 44px;
  .label {
    color: #0076ff;
    background: #fff;
    border: 1px solid #0076ff;
    &.active {
      color: #fff;
      background: #0076ff;
    }
  }
}
</style>
