<template>
  <div class="mine-car pb-68  bg-color flex flex-col">
    <Navbar title="我的车辆" @clickLeft="handleClickBack" :customClickLeft="true">
      <template #right v-if="carListRef.length > 0 && activeTabRef === 0">
        <span class="text_primary" @click="isEdit = !isEdit">
          {{ isEdit ? '确定' : '修改默认' }}
        </span>
      </template>
    </Navbar>
    <div class="px-24 flex-none pt-16" v-if="isShowTrailer">
      <car-tab :tabs="tabs" :activeIndex="activeTabRef" @change="onTabChange" />
    </div>
    <div class="flex-1 px-24 overflow-y-auto pt-16" v-if="activeTabRef === 0">
      <div v-if="carListRef.length > 0">
        <div v-for="(item, index) in carListRef" :key="index">
          <Carcard @changeDfaultCar="handleChangeDefaultCar" :isEdit="isEdit" :index="index" :car="item" />
        </div>
      </div>

      <div class="w-full h-full flex flex-col justify-center items-center" v-else>
        <img
          class="w-134 h-134"
          src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_zanwucheliang%403X.png"
        />
        <span class="text-muted text-16">暂无车辆</span>
      </div>
    </div>

    <div class="flex-1 px-24 overflow-y-auto pt-16" v-else>
      <div v-if="trailerListRef.length > 0">
        <div v-for="(item, index) in trailerListRef" :key="index">
          <TrailerCard :car="item" />
        </div>
      </div>

      <div class="w-full h-full flex flex-col justify-center items-center" v-else>
        <img
          class="w-134 h-134"
          src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_zanwucheliang%403X.png"
        />
        <span class="text-muted text-16">暂无挂车</span>
      </div>
    </div>

    <div class="btn-box">
      <Button size="large" type="primary" @click="handleAddCar" v-if="activeTabRef === 0">添加车辆</Button>
      <Button size="large" type="primary" @click="handleAddTrailer" v-else>添加挂车</Button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed, onBeforeMount } from '@vue/composition-api';
import { Button } from 'vant';
import { useTruckStore } from '@/store/truck';
import { useRouter, useRoute } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';
import { Toast } from 'vant';
import Carcard from './components/carCard';
import CarTab from './components/car-tab.vue';
import TrailerCard from './components/trailerCard';
import { useTruckVerifiedStore, emptyForm } from '@/store/truckVerified';
import { useTrailerVerifiedStore, emptyForm as emptyTrailerForm } from '@/store/trailer';
import AuthModeFactory from '@/services/AuthenticationMode/AuthModeFactory';
import Vehicle from '@/services/AuthenticationMode/Vehicle';
import { getPlatformAppAuditModeApi } from '@/api/common';
import ModeEnum from '@/services/AuthenticationMode/ModeEnum';
import { useUserStore } from '@/store/user';

const authModeFactory = new AuthModeFactory(new Vehicle());

export default defineComponent({
  name: 'MineCar',
  components: {
    Button,
    Toast,
    Carcard,
    CarTab,
    TrailerCard
  },
  setup() {
    const truckStore = useTruckStore();
    const userStore = useUserStore();
    const truckVerifiedStore = useTruckVerifiedStore();
    const trailerStore = useTrailerVerifiedStore();
    const carListRef = computed(() => {
      return truckStore.trucks;
    });
    const trailerListRef = computed(() => {
      return truckStore.trailers;
    });

    const tabs = ref([
      { label: '车辆管理', value: 0 },
      { label: '挂车管理', value: 1 }
    ]);
    const activeTabRef = ref(0);
    const onTabChange = ({ value }) => {
      if (isEdit.value) return;
      activeTabRef.value = value;
      router.push({
        path: '',
        query: {
          type: activeTabRef.value === 0 ? 'truck' : 'trailer'
        },
        replace: true
      });
      refreshList();
    };

    // 默认完整模式
    // truckVerifiedStore.isAmendTruckAuthInfo = false;
    truckVerifiedStore.updateAuthModeFields(authModeFactory.build(ModeEnum.INTEGRITY));

    const noCar = require('@/assets/clkb.png');
    const isEdit = ref(false); // 编辑状态还是列表状态
    const router = useRouter();
    const route = useRoute();
    const isShowTrailer = computed(() => userStore.isTrailerOpen);
    onBeforeMount(async () => {
      const isTrailerOpen = userStore.isTrailerOpen;
      if (!isTrailerOpen) {
        activeTabRef.value = 0;
      } else {
        if (route.query?.type === 'trailer') {
          activeTabRef.value = 1;
        } else {
          activeTabRef.value = 0;
        }
      }
      refreshList();
    });

    const refreshList = async () => {
      const isTruck = activeTabRef.value === 0;
      const actionMethod = isTruck ? truckStore.queryUsersTruck : truckStore.queryUsersTrailer;
      Toast.loading('加载中...');
      await actionMethod();
      Toast.clear();
    };
    const handleChangeDefaultCar = async truckId => {
      truckStore.updateDefaultTruck(truckId);
    };

    const handleAddTrailer = () => {
      trailerStore.updateInfo(emptyTrailerForm());
      router.push('/mine/car/trailer/edit');
    };
    const handleAddCar = async () => {
      // 先清空缓存
      truckVerifiedStore.updateInfo(emptyForm());

      try {
        const res = await getPlatformAppAuditModeApi();

        // 需要请求一个借口判断是什么认证类型
        truckVerifiedStore.updateAuthModeFields(authModeFactory.build(res.data));

        router.push(PageEnum.BASE_CAR_ADD);
      } catch (error) {
        Toast.fail(error);
      }
    };

    return {
      carListRef,
      noCar,
      isEdit,
      tabs,
      activeTabRef,
      onTabChange,
      handleAddTrailer,
      handleAddCar,
      handleClickBack: () => {
        router.push(PageEnum.BASE_MINE);
      },
      handleChangeDefaultCar,
      trailerListRef,
      isShowTrailer
    };
  }
});
</script>
<style lang="less">
.mine-car {
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
}
</style>
