<template>
  <div class="car-card bda-4 pa-16 mb-8" @click="handleClickCard">
    <div class="flex justify-between items-center mb-8">
      <!-- <div class="circle mr-8 text-center"><Icon name="wdcl" /></div> -->
      <div class="flex items-center justify-start">
        <span class="font-500 mr-12 text-18">{{ car.trailerPlateNumber }}</span>
        <span
          v-if="car.plateColor"
          class="car_class h-18 text-10 flex justify-center items-center px-4"
          :style="{
            background: !colorObj.mixinColor
              ? colorObj.color
              : `linear-gradient(to right, ${colorObj.color[0]} 50%, ${colorObj.color[1]} 51%)`,
            color: colorObj.color ? '#fff' : '#333',
            border: colorObj.color ? 'none' : '1px solid #eee'
          }"
        >
          {{ colorObj.value }}
        </span>
        <div
          class="default_class flex justify-center items-center w-32 h-18 text-10 ml-12 rounded-10"
          v-if="car.defaultTruck === 1"
        >
          默认
        </div>
      </div>
      <div v-if="car.auditStatus !== 2" class="text-14" :class="'text' + car.auditStatus">
        {{ stateName }}
      </div>
    </div>
    <div class="text-14 text-muted flex justify-start items-center">
      <span>{{ car.trailerTypeName }}</span>
      <span class="inline-block w-1 h-10 bg-muted mx-12"></span>
      <span>核载{{ gram2weight(car.loadWeight, 2) }}kg</span>
    </div>
    <div v-if="expiredDateArr.length" class="mt-10">
      <span class="tags-date text-12 mr-8" :class="tag.cls" v-for="tag in expiredDateArr" :key="tag.tips">{{
        tag.tips
      }}</span>
    </div>
    <!-- <div class="car-control d-flex" v-if="!isEdit">
        <div v-if="car.auditStatus === 1" class="text_warning">审核中</div>
        <div class="d-flex">
          <div v-if="car.auditStatus === 3" class="text_danger">
            不通过
          </div>
        </div>
      </div> -->
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, watch, computed } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import { carColorMap, carsStateEnum } from '../constants';
import { gram2weight } from '@/utils/unit';
import { getExpiredDateMap } from '@/utils/timeDiff';

export default defineComponent({
  name: 'Carcard',
  props: {
    car: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean
    },
    index: {
      type: Number
    }
  },

  setup(props, ctx) {
    const colorObj = ref({});
    const stateName = ref('');
    const router = useRouter();
    const handleChangeDefaultCar = () => {
      ctx.emit('changeDfaultCar', props.car.trailerId);
    };
    const handleJumpDetail = () => {
      router.push(`/mine/car/trailer/detail?id=${props.car.trailerId}`);
    };

    const handleClickCard = () => {
      handleJumpDetail();
    };

    const carColorBind = () => {
      carsStateEnum.forEach(item => {
        if (props.car.auditStatus == item.key) {
          stateName.value = item.value;
        }
      });
      carColorMap.forEach(item => {
        if (props.car.plateColor == item.key) {
          colorObj.value = item;
        }
      });
    };
    onMounted(async () => {
      carColorBind();
    });

    const expiredDateArr = computed(() => {
      let res = [];
      if (!props.car || !props.car.trailerPlateNumber) return res;
      const { inspectionEndDate, validEndDate } = props.car;
      const { expired, nearExpired } = getExpiredDateMap([
        {
          value: inspectionEndDate,
          label: '行驶证',
          tips: 0,
          unit: 'month'
        },
        {
          value: validEndDate,
          label: '运输证',
          tips: 30,
          unit: 'day'
        }
      ]);
      const expiredTag = expired.map(it => {
        return {
          cls: 'tags-danger',
          tips: `${it}已到期`
        };
      });
      const nearExpiredTag = nearExpired.map(it => {
        return {
          cls: 'tags-warning',
          tips: `${it}即将到期`
        };
      });
      return [...nearExpiredTag, ...expiredTag];
    });

    watch(() => props.car, carColorBind);
    return {
      colorObj,
      stateName,
      gram2weight,
      handleClickCard,
      handleChangeDefaultCar,
      expiredDateArr
    };
  }
});
</script>
<style lang="less" scoped>
.car-card {
  width: 100%;
  background-color: white;
  .circle {
    background-color: rgba(64, 152, 255, 0.1);
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    border-radius: 50%;
    + span {
      line-height: 0.32rem;
    }
  }
  .car-control {
    line-height: 0.32rem;
  }
  .default-block {
    background: rgba(64, 152, 255, 0.1);
    width: 0.4rem;
    line-height: 0.2rem;
    height: 0.2rem;
  }
  .text1 {
    color: #ff8a21 !important;
  }
  .text2 {
    color: #00ac95 !important;
  }
  .text3 {
    color: #e72832 !important;
  }
  .default_class {
    background: rgba(237, 66, 22, 0.1) !important;
    color: #ed4216 !important;
  }
  .tags-date {
    border-radius: 0 3px 3px 3px;
    padding: 2px 4px;
  }
  .tags-danger {
    color: #ffffff;
    background: #e72832;
  }
  .tags-warning {
    border: 1px solid;
    color: #ff7900;
  }
}
</style>
